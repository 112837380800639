<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.isError = false)"
    ></AError>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-card-title>Create New Student</v-card-title>
      <v-stepper elevation="0" :value="step" v-if="step < 6">
        <v-stepper-header>
          <v-stepper-step step="1" :complete="1 < step"> </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="2" :complete="2 < step"> </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step step="3" :complete="3 < step"> </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <div class="d-flex align-center justify-space-around">
        <div v-if="step == 1" class="button-content text-center">
          <v-form ref="formName" v-model="valid" lazy-validation>
            <div>Select the Classroom name?</div>
            <div class="text-center pt-3">
              <SelectField
                :value="classroomId"
                :field="selectField"
                @input="classroomId = $event"
                label="Applicant position"
              ></SelectField>
            </div>
          </v-form>
        </div>
        <div v-if="step == 2" class="form-content text-center">
          <v-form ref="formName" v-model="valid" lazy-validation>
            <TextareaField
              :seletedStudents="students"
              :callbackSelect="getStudents"
            >
            </TextareaField>
          </v-form>
        </div>

        <div v-else-if="step == 3" class="form-content text-center">
          <v-form ref="formName" v-model="valid" lazy-validation>
            <Preview @close="back" :students="students" />
          </v-form>
        </div>
      </div>
      <div class="d-flex align-center justify-space-around">
        <div v-if="step == 1" class="button-content text-center">
          <div class="text-center pt-1">
            <v-btn width="75%" rounded color="primary" dark @click="next()" :disabled="students.length == 0">
              Next
            </v-btn>
          </div>
          <div class="text-center pt-2">
            <v-btn
              width="75%"
              rounded
              outlined
              color="primary"
              dark
              @click="$router.go(-1)"
            >
              Cancel
            </v-btn>
          </div>
        </div>
        <div v-else-if="step == 2" class="button-content text-center">
          <div class="text-center pt-1">
            <v-btn width="75%" rounded color="primary" dark @click="next()">
              Next
            </v-btn>
          </div>
          <div class="text-center pt-2">
            <v-btn
              width="75%"
              rounded
              outlined
              color="primary"
              dark
              @click="back()"
            >
              Back
            </v-btn>
          </div>
        </div>
        <div v-else-if="step == 3" class="button-content text-center">
          <div class="text-center pt-1">
            <v-btn
              width="75%"
              :loading="api.isLoading"
              rounded
              color="primary"
              dark
              @click="submit()"
            >
              Submit
            </v-btn>
          </div>
          <div class="text-center pt-2">
            <v-btn
              width="75%"
              rounded
              outlined
              color="primary"
              dark
              @click="back()"
            >
              Back
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import Preview from "@/components/Educator/Student/Preview";
import { mapState } from "vuex";
import TextareaField from "../../components/Form/TextareaFieldStudent.vue";
import SelectField from "@/components/Form/SelectField.vue";
export default {
  components: {
    Preview,
    TextareaField,
    SelectField,
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    step: 1,
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    name: null,
    students: [],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC

    //BOC:[api]
    institutionApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    classroomApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    selectField: {
      defaultValue: "",
      name: "Classroom",
      options: [],
      optionText: "name",
      optionValue: "id",
    },
    classroomId: null,
  }),
  created() {
    this.institutionApi.method = "GET";
    this.institutionApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/" +
      this.$route.params.id;

    this.institutionApi.callbackReset = () => {
      this.institutionApi.isLoading = true;
      this.institutionApi.isError = false;
    };

    this.institutionApi.callbackError = (e) => {
      this.institutionApi.isLoading = false;
      this.institutionApi.isError = true;
      this.institutionApi.error = e;
    };

    this.institutionApi.callbackSuccess = (resp) => {
      this.institutionApi.isLoading = false;
      this.updateBreadcrumb(resp);
    };
    this.classroomApi.method = "GET";
    this.classroomApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/" +
      this.$route.params.id +
      "/classroom";

    this.classroomApi.callbackReset = () => {
      this.classroomApi.isLoading = true;
      this.classroomApi.isError = false;
    };

    this.classroomApi.callbackError = (e) => {
      this.classroomApi.isLoading = false;
      this.classroomApi.isError = true;
      this.classroomApi.error = e;
    };

    this.classroomApi.callbackSuccess = (resp) => {
      this.classroomApi.isLoading = false;
      this.selectField.options = resp;
      this.classroomId = resp[0].id;
    };

    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/" +
      this.$route.params.id +
      "/student/add";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = () => {
      this.api.isLoading = false;
      this.$router.push({
        name: "PageEducatorInstitutionStudent",
        params: { id: this.$route.params.id },
      });
    };
  },
  mounted() {
    this.$api.fetch(this.institutionApi);
    this.$api.fetch(this.classroomApi);
  },
  methods: {
    updateBreadcrumb(resp) {
      this.breadcrumbs.push({
        text: "Dashboard",
        to: { name: "PageEducatorDashboard" },
        exact: true,
      });
      this.breadcrumbs.push({
        text: resp.name,
        to: {
          name: "PageEducatorInstitutionDashboard",
          params: { id: this.$route.params.id },
        },
        exact: true,
      });

      this.breadcrumbs.push({
        text: "Students",
        to: {
          name: "PageEducatorInstitutionStudent",
          params: { id: this.$route.params.id },
        },
        exact: true,
      });
      this.breadcrumbs.push({
        text: "New Student",
        to: { name: "PageEducatorInstitutionStudentAdd" },
        exact: true,
      });
    },
    close() {
      this.$router.push({
        name: "PageEducatorInstitutionStudent",
        params: { id: this.$route.params.id },
      });
    },
    submit() {
      this.api.params = this.$_.clone({ names: this.students, classroomId:this.classroomId });
      this.$api.fetch(this.api);
    },
    next() {
      this.step++;
    },
    back() {
      this.step--;
    },
    getStudents(val) {
      this.students = val;
      for (var i = 0; i < this.students.length; i++) {
        if (this.students[i].name == "") {
          this.students.splice(i, 1);
          i--;
        }
      }
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 600px !important;
}
.button-content {
  width: 300px !important;
}
</style>